import rsIcon from '../assets/icon.svg';

import * as styles from './footer.module.css';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer class={styles.footer}>
      &copy; Copyright {year}{' '}
      <a
        class={styles.rsIcon}
        href="https://www.retrosquare.com"
        target="_blank"
      >
        <img
          alt="Retrosquare LLC"
          title="Copyright Retrosquare LLC"
          src={rsIcon}
        />
      </a>{' '}
      | <a href="/terms">Terms of Use</a> | Rebuilt with ❤ in Meridian, USA
    </footer>
  );
}
