.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.header h1 {
  font-family: 'Brentscript';
  color: #ff8134;
  margin-bottom: 0;
  font-size: 4rem;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000, 0.25rem -0.4rem #000000;
}

.header h1 > a {
  text-decoration: none;
  border-bottom: none;
  font-family: 'Brentscript';
  color: #ff8134;
}

.oo {
  font-family: 'Brentscript';
  color: #aedb4e;
}

.header h2 {
  margin-top: -1.5rem;
  font-weight: normal;
}

@media (max-width: 25rem) {
  .header h1 {
    font-size: 2.5rem;
  }
  .header h2 {
    font-size: 0.9375rem;
  }
}
