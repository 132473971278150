import { useCallback, useState } from 'preact/hooks';

import uuid from '../utils/uuid';
import fileToDataURL from '../utils/fileToDataURL';
import fileToMd5 from '../utils/fileToMd5';

export default function ImageButton({
  label,
  name,
  onInput,
  class: className,
}) {
  const id = uuid();
  const [background, setBackground] = useState();

  const handleInput = useCallback(
    async (e) => {
      const file = e?.target?.files[0];
      if (file) {
        const dataUrl = await fileToDataURL(file);
        const md5 = await fileToMd5(file);
        setBackground(dataUrl);
        if (onInput) {
          onInput(e, { name, file, dataUrl, md5 });
        }
      }
    },
    [onInput]
  );

  const labelStyle = {};
  if (background) {
    labelStyle.backgroundImage = `url(${background})`;
    labelStyle.backgroundSize = 'cover';
    labelStyle.backgroundPosition = 'center';
    labelStyle.imageRendering = 'pixelated';
  }

  const inputStyle = {
    position: 'absolute',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
  };

  return (
    <div class={className}>
      <label for={id} style={labelStyle}>
        {!background && label}
      </label>
      <input
        id={id}
        name={name}
        style={inputStyle}
        type="file"
        accept="image/*"
        onInput={handleInput}
      />
    </div>
  );
}
