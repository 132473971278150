import { useCallback, useState } from 'preact/hooks';

import { Footer, Header, ImageButton } from '../components';
import { post } from '../utils/http';

import * as styles from './home.module.css';

export default function App() {
  const [collection, setCollection] = useState({ images: {} });
  const [uploaded, setUploaded] = useState(0);
  const [progress, setProgress] = useState(0);

  const handleProgress = useCallback(
    (e) => {
      setProgress(e.loaded / e.total);
    },
    [setProgress]
  );

  const uploadFile = useCallback(
    async ({ name, uploadUrl: { fields, url } }) => {
      try {
        const file = document.querySelector(
          `input[type="file"][name="${name}"]`
        ).files[0];

        const formData = new FormData();
        formData.append('Content-Type', file.type);
        new URLSearchParams(fields).forEach((v, k) => {
          formData.append(k, v);
        });
        formData.append('file', file);

        const result = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('post', url, true);
          xhr.upload.onprogress = handleProgress;
          xhr.upload.onabort = reject;
          xhr.upload.onerror = reject;
          xhr.upload.onload = (e) => {
            setProgress(0);
            setUploaded((x) => x + 1);
            resolve(e);
          };
          xhr.send(formData);
        });

        return result;
      } catch (err) {
        throw err;
      }
    },
    [collection]
  );

  const handleInput = useCallback((_, { name, file, dataUrl, ...data }) => {
    setCollection((col) => {
      col.images = { ...col.images, [name]: { name, data } };
      return col;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    const response = await post(
      'https://91cba8spzg.execute-api.us-west-2.amazonaws.com/api/uploads',
      {
        images: Object.values(collection.images),
      }
    );

    await response.images.reduce((p, image) => {
      return p.then(() => uploadFile(image));
    }, Promise.resolve());

    window.location.assign(`/${response.shortcode}`);
  }, [collection]);

  const percentDone = ((uploaded + progress) / collection.images.length) * 100;
  const buttonStyles = {
    backgroundImage: `linear-gradient(
      to right,
      #7aa421 0,
      #7aa421 ${percentDone}%,
      #aedb4e ${percentDone}%,
      #aedb4e 100%
    )`,
  };

  return (
    <>
      <Header />
      <main class={styles.main}>
        <ImageButton
          class={styles.image}
          label="click to add evidence"
          name="image1"
          onInput={handleInput}
        />
        <ImageButton
          class={styles.image}
          label="click to add evidence"
          name="image2"
          onInput={handleInput}
        />
        <ImageButton
          class={styles.image}
          label="click to add evidence"
          name="image3"
          onInput={handleInput}
        />
        <ImageButton
          class={styles.image}
          label="click to add evidence"
          name="image4"
          onInput={handleInput}
        />
        <button style={buttonStyles} onClick={handleSubmit}>
          click here to proove.it
        </button>
      </main>
      <Footer />
    </>
  );
}
