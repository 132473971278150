import { useEffect, useState } from 'preact/hooks';

import { Loader, Footer, Header } from '../components';
import { post } from '../utils/http';

import * as styles from './proof.module.css';

export default function Proof({ code }) {
  const [images, setImages] = useState([]);

  useEffect(async () => {
    const response = await post(
      `https://91cba8spzg.execute-api.us-west-2.amazonaws.com/api/images`,
      {
        code,
      }
    );
    setImages(response.images);
  }, [code]);

  return (
    <>
      <Header />
      {images.length < 1 ? (
        <main class={styles.loading}>
          <Loader />
        </main>
      ) : (
        images.map((i, n) => {
          const style = {};
          style.backgroundImage = `url(${i.url})`;
          style.backgroundSize = 'cover';
          style.backgroundPosition = 'center';

          return (
            <article class={styles.proof}>
              <div>
                <h3>Exhibit {n + 1}</h3>
                <a class={styles.image} href={i.url} style={style}>
                  &nbsp;
                </a>
                <a class={styles.report}>Mark this image as inappropriate.</a>
              </div>
            </article>
          );
        })
      )}
      <Footer />
    </>
  );
}
