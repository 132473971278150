import crypto from 'crypto';

export default function fileToMd5(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(
        ['base64', 'hex'].reduce((acc, type) => {
          const hash = crypto.createHash('md5');
          hash.update(reader.result);
          acc[type] = hash.digest(type);
          return acc;
        }, {})
      );
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
}
