.main {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 18rem));
  justify-content: space-around;
  margin: 0 auto;
  max-width: calc(41rem + 2px);
  padding: 2rem;
  border: 1px solid #758691;
  background-color: #f5f5f5;
}

.main button {
  height: 3rem;
  background: #aedb4e;
  border: 2px solid #7aa421;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  grid-column: 1 / -1;
}

.image {
  border: 2px solid #e6e6e6;
  position: relative;
  background-color: rgba(255, 255, 255, 0.25);
}

.image::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.image label {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 1.25rem;
  text-align: center;
}
