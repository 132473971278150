import * as styles from './header.module.css';

export default function Header() {
  return (
    <header class={styles.header}>
      <h1>
        <a href="/">
          pr<span class={styles.oo}>oo</span>ve.it!
        </a>
      </h1>
      <h2>don't just say it, proove.it</h2>
    </header>
  );
}
