.footer {
  padding: 2rem 1rem;
  text-align: center;
}

.footer .rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
  background: white;
  vertical-align: sub;
}

.footer .rsIcon {
  border-bottom: none;
}
