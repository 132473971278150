.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.proof {
  width: 100%;
  border: 1px solid #758691;
  padding: 2rem;
  max-width: calc(41rem + 2px);
  margin: 0 auto;
  background-color: #f5f5f5;
  margin-bottom: 1rem;
  position: relative;
}

.proof::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.proof > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 1.25rem;
  text-align: center;
}

.image {
  width: 75%;
  height: 75%;
  display: block;
  text-decoration: none;
  border-bottom: none;
  background-color: #ccc;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.proof h3 {
  margin-bottom: 1rem;
  margin-top: 0;
  font-weight: normal;
}

.report {
  margin-top: 1rem;
  font-size: 0.75rem;
}
