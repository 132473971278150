import { useRoutes } from 'hookrouter';

import Home from './pages/Home';
import Proof from './pages/Proof';
import Terms from './pages/Terms';

const routes = {
  '/': () => <Home />,
  '/terms': () => <Terms />,
  '/:code': ({ code }) => <Proof code={code} />,
};

const App = () => {
  const routeResult = useRoutes(routes);
  return routeResult;
};

export default App;
