.terms {
  width: 100%;
  border: 1px solid #758691;
  padding: 2rem;
  max-width: calc(41rem + 2px);
  margin: 0 auto;
  background-color: #f5f5f5;
  margin-bottom: 1rem;
  position: relative;
}
